import React from 'react'
import './LogoBanner.scss';

export function LogoBanner() {

    const logos = [
        {logo: 'https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/react_mmnios.svg', name: 'react'}, 
        {logo: 'https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/expressjs_znul9q.svg', name:"express"},
        {logo: 'https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/gatsby_rw7uba.svg', name: 'gatsby'},  
        {logo: 'https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/mongo_g6zz7w.svg', name: 'mongo'}, 
        {logo: 'https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149407/typescript_euxcqb.svg', name: 'typescript'}, 
        {logo: 'https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/mysql_kws7pj.svg', name: 'mysql'}
    ];

    return (
        <section className="logo-section">
            <div className="container">
                { 
                    logos.map( (logo, i) => {
                        return (
                        <div className={`banner-container banner-container-${logo.name}`} >
                            <img src={logo.logo} key={i} className={`banner-logo banner-logo-${logo.name}`} />
                        </div>
                        )
                    }
                )}
            </div>
        </section>
    )
}
