import React from 'react';

export default function BlobFive({className, color}) {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 249.11 195.42"
            className={className}
        >
            <path fill={color} className="cls-1" d="M274.55,223.06c0,69.95-109.25-31.91-178.42-24.8-69.59,7.15-34.91-26.51-64.49-85.93C.48,49.71,94.1,95.86,151.91,56.48,194,27.8,274.55,153.11,274.55,223.06Z" transform="translate(-25.45 -52.29)"/>
        </svg>
    )
}