import React from 'react';
import NavbarTwo from '../components/Navbar/NavbarTwo';
import {LogoBanner} from '../components/Logo Banner/LogoBanner';
import { Projects } from '../components/Projects/Projects';
import { Technologies } from '../components/Technologies/Technologies';
import { Footer } from '../components/Footer/Footer';
import { Wireframes } from '../components/Wireframes/Wireframes';

export default function CompSci() {
    return (
        <>
            <NavbarTwo />
            <LogoBanner />
            <Projects />
            <Technologies />
            <Wireframes />
            <Footer />
        </>
    )
}
