import React from 'react';
import Label from '../Label/Label';
import { Wireframe } from './Wireframe/Wireframe';
import './Wireframes.scss';
import { useTheme } from '../../contexts/ThemeContext';

export function Wireframes() {
    const { backgroundColor } = useTheme();
    return (
            <div className="wireframes-section">
                <svg  
                    data-name="Layer 1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 1200 120" 
                    preserveAspectRatio="none"
                >
                    <path  
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
                        class="wf-shape-fill" 
                        fill={ backgroundColor }
                        fill-opacity="1"
                    >
                    </path>
                </svg>
                <div className="wireframes-section-content-container">
                    <Label 
                        title='Wireframes'
                    />
                    <div className="wireframes-container">
                        <Wireframe  
                            image='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631146914/Wireframe_preview_1_nl5rlp.png'
                            modalImage="https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631365771/Advanced_P_-_1920_2_zeuudd.png"
                            title="Applied Food Diagnostics"
                            subtitle="Advanced Hybrid"
                        />
                        <Wireframe  
                            image='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631146915/Wireframe_preview_2_w6aedm.png'
                            modalImage="https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631365990/Classic_-_1920_s0pe0q.png"
                            title="Applied Food Diagnostics"
                            subtitle="Classic"
                        />
                        <Wireframe  
                            image='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631146416/Wireframe_preview_3_vihn8v.png'
                            modalImage="https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631365997/Progressive_Mono_G_-_1920_1_tqxcgw.png"
                            title="Applied Food Diagnostics"
                            subtitle="Progressive"
                        />
                    </div>
                </div>
            </div>

    )
}
