import React, { useState } from 'react';
import Label from '../Label/Label';
import BlobFive from '../Blobs/BlobFive';
import { Technology } from './Technology/Technology';
import { useTheme } from '../../contexts/ThemeContext';
import './Technologies.scss';

export function Technologies() {

    const { paragraphTextColor, backgroundColor, backgroundColorTwo } = useTheme();

    return (
        <>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 1440 320" 
                style={{backgroundColor: backgroundColorTwo}}>
                <path 
                    fill={backgroundColor} 
                    fillOpacity="1" 
                    d="M0,288L34.3,266.7C68.6,245,137,203,206,160C274.3,117,343,75,411,90.7C480,107,549,181,617,202.7C685.7,224,754,192,823,165.3C891.4,139,960,117,1029,90.7C1097.1,64,1166,32,1234,53.3C1302.9,75,1371,149,1406,186.7L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z">
                </path>
            </svg>
            <div className="technologies-section-container" style={{backgroundColor: backgroundColor, marginTop: -5}}>
                <BlobFive color="#C599FF" className="technologies-blob" />
                <div className="content-container">
                    <Label title="Technologies" />
                    <p className="technologies-description" style={{color: paragraphTextColor}}>
                        Although it is rather difficult for a modern FullStack developer to create an exhaustive list
                        of the technologies and tools that they use, the technolgies listed below (and above) encapsulate 
                        a lot of the ones I work with on a regular basis. 
                    </p>
                    <div className="technologies-container">
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147241/node_tb24yh.png'
                            title="NodeJS" 
                            description="Used for most production level backends"
                            backgroundColor="#f6f6f6" 
                            border={0}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147241/rust_ocba5z.png'
                            title="Rust" 
                            description="A language I've been learning to eventually become my main language."
                            backgroundColor="#f6f6f6" 
                            border={1}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147239/bootstrap_eluk9f.png'
                            title="Bootstrap" 
                            description="Primary UI Library for developing websites"
                            backgroundColor="#f6f6f6" 
                            border={0}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147240/jest_i93lal.png'
                            title="Jest" 
                            description="My frontend testing framework of choice"
                            backgroundColor="#f6f6f6" 
                            border={1}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147241/sass_livviy.png'
                            title="Sass/Scss" 
                            description="Used in essentially every frontend project I create to improve dev experience"
                            backgroundColor="#f6f6f6" 
                            border={0}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147240/mui_n6l7vj.png'
                            title="Mui" 
                            description="Secondary UI Library for applications with a more desktop feel"
                            backgroundColor="#f6f6f6" 
                            border={1}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147239/git_vmdn87.png'
                            title="Git" 
                            description="Version Control, used in all serious projects"
                            backgroundColor="#f6f6f6" 
                            border={0}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147239/creativecloud_l9d4aq.png'
                            title="Adobe Creative Cloud" 
                            description="Photoshop and Illustrator are most of what I use, typically for creating digital assets"
                            backgroundColor="#f6f6f6" 
                            border={1}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147240/mongoose_uk7xzq.png'
                            title="Mongoose" 
                            description="ORM Tool for connecting backend to MongoDB"
                            backgroundColor="#f6f6f6" 
                            border={0}
                        />
                        <Technology 
                            imgSrc='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147242/sequelize_zcdiqx.png'
                            title="Sequelize" 
                            description="ORM Tool for connecting backend to SQL Databases"
                            backgroundColor="#f6f6f6" 
                            border={1}
                        />
            
                    </div>
                </div>
            </div>
        </>
    )
}
