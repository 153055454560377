import React, { useState } from 'react';
import Label from '../Label/Label';
import { useTheme } from '../../contexts/ThemeContext';
import { Project } from './Project/Project';
import { BiRightArrow } from 'react-icons/bi';
import { BiLeftArrow } from 'react-icons/bi';
import Resume from '../../assets/Resume.pdf';
import { Link } from 'gatsby';
import './Projects.scss';

export function Projects() {
    const [projectNumber, setProjectNumber] = useState(0);
    const incrementProjectNumber = () => setProjectNumber(projectNumber + 1);
    const decrementProjectNumber = () => setProjectNumber(projectNumber - 1);

    const updateProjectNumber = ( type ) => {
        if(type === 'increment'){
            projectNumber >= 2 ? setProjectNumber(0) : incrementProjectNumber();
        } else if (type === 'decrement'){
            projectNumber === 0 ? setProjectNumber(1) : decrementProjectNumber();
        }
    }

    const {isDarkTheme, paragraphTextColor, backgroundColorTwo } = useTheme();
    
    return (
        <section className="projects-target" style={{backgroundColor: backgroundColorTwo}}>
            <div className="projects-section" style={{color: paragraphTextColor}}>
                <div className="content-container">
                    <Label title="Projects" />
                    <p className="projects-description">
                        While I don't have the most projects on here, I am working on updating this section on a regular basis.
                        Most of my projects outside of work aren't fit for displaying in an iframe, either becasue
                        they don't have a UI or weren't ever properly finished. Nonetheless, more projects will be put here 
                        regularly. 
                    </p>
                    <p className="projects-description">                        
                        Soon, I will be updating the site with a bunch of the things I've created at work over the past year. 
                        This ranges from websites to node applications, wireframes and digital assets.
                    </p>
                    <p className="projects-description">
                        On top of this, more personal projects will be available soon!
                    </p>
                    <p className="projects-description">
                        My Resume can be downloaded 
                        <a 
                            href={Resume} 
                            target="_blank"
                        >
                            here.
                        </a>
                    </p>
                    <div className="projects-container"  style={{backgroundColor:isDarkTheme ? '#232323' : '#F9F5FF' }}>
                        <Project 
                            link="https://www.callahanlovecchiolaw.com" 
                            github="https://github.com/trentungard/lawfirm-react"
                            isHidden={ !(0 === projectNumber) } 
                            title="Callahan Lovecchio Law"
                            type="Brochure Site"
                            description="This project is a simple brochure site, 
                            built with React on the frontend and Express on the 
                            backend to process form submissions."
                            stack={[
                                {
                                    'name': 'Heroku',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147240/heroku_uafmfh.png' />,
                                    'color': '#E6E6E9'
                                },
                                {
                                    'name': 'React',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/react_mmnios.svg' />,
                                    'color': '#CDEAF4'
                                },
                                {
                                    'name': 'Express',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/expressjs_znul9q.svg' />,
                                    'color': '#FEF9EF'
                                },
                                {
                                    'name': 'Bootstrap',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147239/bootstrap_eluk9f.png' />,
                                    'color': '#CDB0F5'
                                },
                                {
                                    'name': 'Sass',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147241/sass_livviy.png' />,
                                    'color': '#D7B8E0'
                                }
                        ]}
                        />
                        <Project 
                            link="https://www.keystonestatecamp.com" 
                            // github="https://github.com/trentungard/lawfirm-react"
                            isHidden={ !(1 === projectNumber) } 
                            title="Keystone State Camp"
                            type="Ecommerce"
                            description="This project is an ecommerce site
                            built on Woocommerce which combines a simplistic,
                            classic looking design with the power of ecommerce."
                            color={ isDarkTheme ? '#232323' : '#F9F5FF'}
                            stack={[
                                {
                                    'name': 'Wordpress',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147243/wordpress_uflzs2.png' />,
                                    'color': '#E6E6E9'
                                },
                                {
                                    'name': 'JavaScript',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147240/js_a1da1x.png' />,
                                    'color': '#F3E8A5'
                                },
                                {
                                    'name': 'Woocommcerce',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147242/woo_n5rfju.png' />,
                                    'color': '#CFC5E8'
                                },
                                {
                                    'name': 'GoDaddy',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147240/godaddy_mwezp5.png' />,
                                    'color': '#E1F0F4'
                                }
                            ]}
                        />
                        <Project 
                            link="https://documentportal.appliedfooddiagnostics.com" 
                            isHidden={ !(2 === projectNumber) } 
                            title="Applied Food Diagnostics Document Portal"
                            type="Full Stack Web Application"
                            description="This is a simple frontend that I built to fetch documents
                            for our customers to view. While the application is Full Stack, I worked 
                            mostly on the frontend. There is also an Admin Portal with Authentication."
                            color={ isDarkTheme ? '#232323' : '#F9F5FF'}
                            stack={[
                                {
                                    'name': 'React',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/react_mmnios.svg' />,
                                    'color': '#CDEAF4'
                                },
                                {
                                    'name': 'Digital Ocean',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147239/digitalocean_lg20pr.png' />,
                                    'color': '#A39DFB'
                                },
                                {
                                    'name': 'Firebase',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147239/firebase_jbnndx.png' />,
                                    'color': '#EFDBA9 '
                                },
                                {
                                    'name': 'Redux',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147241/redux_ldi1ky.png' />,
                                    'color': '#B6A6DD'
                                },
                                {
                                    'name': 'Sass',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631147241/sass_livviy.png' />,
                                    'color': '#D7B8E0'
                                },
                                {
                                    'name': 'MySQL',
                                    'image': <img class="stack-image" src='https://res.cloudinary.com/dw9rh3ofu/image/upload/v1631149406/mysql_kws7pj.svg' />,
                                    'color': '#FFE7D6'
                                }
                            ]}
                        />
                        <BiLeftArrow color="#000" size={30} className="arrow-icon arrow-left" onClick={() => updateProjectNumber('decrement')} />
                        <BiRightArrow color="#000" size={30} className="arrow-icon arrow-right" onClick={() => updateProjectNumber('increment')}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
