import React from "react";
import './WireframeModal.scss';

export const WireframeModal = ({style, setShowModal, image}) => {
    return(
        <div 
            className="wireframe-modal" 
            style={style}
            onClick={() => setShowModal(false)}
        >
            <div className="wireframe-modal-container">
                <img src={image} />
            </div>
        </div>
    )
}