import React from 'react';
import './Technology.scss';

export function Technology({imgSrc, title, description, application, backgroundColor, border}) {
    return (
        <div className={`technology-container ${border === 1 ? 'container-green' : 'container-purple'}`} style={{backgroundColor: backgroundColor}}>
            <img className="technology-icon" src={imgSrc} />
            <h3 className="technology-title">{title}</h3>
            { description ? <p className="technology-description">{description}</p> : null}
            { application ? <p className="technology-application">{application}</p> : null}
        </div>
    )
}
