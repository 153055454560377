import React, { useState } from 'react';
import { WireframeModal } from './Modal/WireframeModal';
import './Wireframe.scss';

export function Wireframe({image, modalImage, title, subtitle}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <div className="wireframe">
            <WireframeModal
                style={{display: showModal ? 'flex' : 'none'}}
                setShowModal={setShowModal}
                image={modalImage}
            />
            <img 
                style={{width: 330, height: 250}}
                className="wireframe-image"
                src={image}
            />
            <h3
                className="title"
            >{title}
            </h3>
            <p
                className="subtitle"
            >{subtitle}</p>
            <button 
                className="wireframe-button"
                onClick={() => setShowModal(true)}
            >
                View
            </button>
        </div>
    )
}
